/* library css start */
@import 'bootstrap/dist/css/bootstrap.min.css';
@import 'aos/dist/aos.css';
/* library css end */

/* fonts link */


:root{
  --montserrat: 'Montserrat', sans-serif;
  --black:#000000;
  --txt1:#1D252C;
  --txt2:#999;
  --white:#ffffff;
  --darkpink:#B81464;
  --lightpink:#F70B7F;
  --blue:#4915C5;
  --bg1:#F3F4F4;
  --golden:rgba(255, 222, 138, 0.88);
}

/* coman css start */
html{
  overflow-x: hidden;
}
body {
  font-size: 18px;
  margin: 0;
  font-family: var(--montserrat);
  color: var(--black);
  overflow-x: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin-top: 0;
  margin-bottom: 0;
}

img {
  max-width: 100%;
}

a:hover,
a {
  text-decoration: none;
  outline: 0;
  transition: all 0.5s;
}

a:focus {
  text-decoration: none;
  outline: 0;
}

::-moz-selection {
  background: var(--darkpink);
  color: var(--white);
}

::selection {
  background: var(--darkpink);
  color: var(--white);
}

ul {
  margin: 0px;
  padding: 0px;
  list-style: none;
}
select,
textarea,
input[type="tel"],
input[type="number"],
input[type="date"],
input[type="email"],
input[type="text"]{
  height: 60px;
  border: 1px solid rgba(29, 37, 44, 0.25);
  padding: 0 17px;
  font-size: 16px;
  color: var(--txt1);
  font-weight: 400;
  border-radius: 0;
  width: 100%;

}

textarea{
  width: 100%;
}
textarea::placeholder,
input[type="number"]::placeholder,
input[type="date"]::placeholder,
input[type="email"]::placeholder,
input[type="text"]::placeholder{
  color: #1D252C;
}
form button,
form input[type="submit"],
form input[type="button"]{
  border-radius: 5px;
  background: linear-gradient(287deg, #F70B7F 3%, #9A249C 60%, #4F0DCF 97%);
  color: var(--white);
  height: 60px;
  transition: all 0.5s;
  border: none;
  padding: 0 20px;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.1px;
  text-transform: uppercase;
}
form button:hover,
form input[type="submit"]:hover,
form input[type="button"]:hover{
  background: linear-gradient(287deg, #4F0DCF 3%, #9A249C 60%,  #F70B7F 97%);
}
select,
button:focus,
input:focus,
img:focus,
.slick-slide:focus,
textarea:focus,
input:focus {
  outline: none;
}
h1{
  font-size: 36px;
  font-weight: 800;
  margin-bottom: 28px;
}
.f54{
  font-size: 54px;
}
@media (max-width:1600px) {
  h1{
    font-size: 30px;
  }
  .f54{
    font-size: 44px;
  }
}
@media (max-width:1199px) {
  h1{
    font-size: 32px;
    margin-bottom: 20px;
  }
  h1 br{ display: none;}
  .f54{
    font-size: 34px;
  }
}
@media (max-width:1199px) {
  h1{
    font-size: 30px;
  }
}
@media (max-width:991px) {
  /* h1{
    font-size: 30px;
  } */
  .f54{
    font-size: 30px;
  }
}
@media (max-width:767px) {
  h1{
    font-size: 20px;
  }
  .f54{
    font-size: 20px;
  }
}

h2{
  font-size: 34px;
  font-weight: 800;
  margin-bottom: 20px;
  color: var(--txt1);
}
.section_title{
  position: relative;
  padding-top: 32px;
}
.section_title::before{
  content: "";
  position: absolute;
  top: 0;
  background-color: var(--lightpink);
  width: 30px;
  height: 2px;
}
.section_title.bottom{
  padding-bottom: 32px;
  margin-bottom: 20px;
  padding-top: 0;
}
.section_title.bottom h2{
  margin-bottom: 0;
}
.section_title.bottom::before{
  top: unset;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}
.center h2{
  text-align: center;
}

@media (max-width:1600px) {
  h2{
    font-size: 32px;
  }
}
@media (max-width:1400px){
  textarea,
  input[type="tel"],
  input[type="number"],
  input[type="date"],
  input[type="email"],
  input[type="text"]{
    font-size: 14px;
    padding: 0 15px;
  }
}
@media (max-width:1199px) {
  h2{
    font-size: 30px;
  }
  h2 br{
    display: none;
  }
}
@media (max-width:991px) {
  h2{
    font-size: 28px;
  }
}
@media (max-width:767px) {
  h2{
    font-size: 20px;
  }
}

.f24{
  font-size: 24px;
}
h3{
  font-size: 36px;
  color: var(--txt1);
  margin-bottom: 20px;

}
@media (max-width:1600px) {
  .f24{
    font-size: 22px;
  }
  h3{
    font-size: 30px;
  }
}
@media (max-width:1199px) {
  .f24{
    font-size: 20px;
  }
  h3{
    font-size: 28px;
  }
}
@media (max-width:991px) {
  .f24{
    font-size: 18px;
  }
  h3{
    font-size: 20px;
  }
}
@media (max-width:767px) {
  h3{
    font-size: 18px;
  }
}
@media (max-width:575px) {
  
}
h4{
  font-size: 24px;
  font-weight: 800;
}
@media (max-width:1600px) {
  h4{
    font-size: 22px;
  }
}
@media (max-width:1199px) {
  h4{
    font-size: 20px;
  }
}
@media (max-width:991px) {
  h4{
    font-size: 18px;
  }
}
@media (max-width:767px) {
  h4{
    font-size: 16px;
  }
}
@media (max-width:575px) {
  
}
.cWhite{
  color: var(--white);
}
p{
  font-size: 18px;
  font-weight: 400;
  color: var(--black);
  margin-top: 20px;
  line-height: 150%;
}
@media (max-width:1600px) {
  p{
    font-size: 17px;
  }
}
@media (max-width:1199px) {
  p{
    font-size: 16px;
  }
}
@media (max-width:991px) {
  p br{
    display: none;
  }
}
@media (max-width:767px) {
  p{
    font-size: 14px;
  }
}
@media (max-width:575px) {
  
}
.f16{
  font-size: 16px;
}
.white h1,
.white h2,
.white h3,
.white h4,
.white p{
  color: var(--white);
}
.fw-300{
  font-weight: 300;
}
.fw-800{
  font-weight: 800;
}
.fw-700{
  font-weight: 700;
}
.fw-600{
  font-weight: 600;
}
.fw-300{
  font-weight: 300;
}
strong{
  font-weight: 700;
}
.sub_heading{
  display: inline-block;
  font-size: 18px;
  color: var(--black);
  font-weight: 600;
  padding-left: 44px;
  position: relative;
  margin-bottom: 20px;
  letter-spacing: 4.5px;
  text-transform: uppercase;
}
.white .sub_heading{
  color: var(--white);
}
.sub_heading::before{
  content: "";
  position: absolute;
  top: 10px;
  left: 0;
  width: 24px;
  height: 2px;
  background-color: var(--darkpink);

}
.white .sub_heading::before{
  background-color: var(--white);
}
.sub_heading.white::before{
  background-color: var(--white);
}
.cmn_btn{
  border-radius: 5px;
  background: linear-gradient(279deg, #F70B7F 3%, #9A249C 60%, #4F0DCF 97%);
  display: inline-block;
 padding: 2px;
  color: var(--black);
  text-align: center;
  font-family: var(--montserrat);
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  letter-spacing: 0.1px;
  text-transform: uppercase;
  transition: all 0.5s;
  margin-top: 50px;
  border: none;
}
.cmn_btn span{
  background-color: var(--white);
  border-radius: 5px;
  display: block;
  padding: 12px 15px;
  transition: all 0.5s;
}
.cmn_btn:hover span{
  background-color: transparent;
  color: var(--white);
}
.white .cmn_btn{
  background: transparent;
  color: var(--white);
  border: 2px solid var(--white);
}
.white .cmn_btn span{
  background-color: transparent;
}
.white .cmn_btn:hover{
  background: linear-gradient(279deg, #F70B7F 3%, #9A249C 60%, #4F0DCF 97%);
}
.cmn_btn_fill{
  border-radius: 5px;
  background: linear-gradient(279deg, #F70B7F 3%, #9A249C 60%, #4F0DCF 97%);
  display: inline-block;
  padding: 12px 15px;
  color: var(--white);
  text-align: center;
  font-family: var(--montserrat);
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  letter-spacing: 0.1px;
  text-transform: uppercase;
  transition: all 0.5s;
  margin-top: 35px;
}

.cmn_btn_fill:hover{
  background: linear-gradient(287deg, #4F0DCF 3%, #9A249C 60%,  #F70B7F 97%);
}

.section{
  padding: 150px 0;
}
.sp_bottom{
  padding-bottom: 150px;
}
.sp_top{
  padding-top: 150px;
}
.section_right{
  padding-left: 70px;
}
@media (min-width:1200px){
  .container{
    max-width: 1920px;
    margin: 0 auto;
    padding: 0 70px;
  }

}
@media (max-width:1600px){
  .section {
    padding: 120px 0;
}
.sp_bottom {
  padding-bottom: 120px;
}
.sp_top{
  padding-top: 120px;
}
.sub_heading{
  font-size: 16px;
}
}
@media (max-width:1199px){
  .section {
    padding: 100px 0;
}
.sp_bottom {
  padding-bottom: 100px;
}
.sp_top{
  padding-top: 100px;
}
  .section_right{
    padding-left: 0;
  }
  .sub_heading{
    font-size: 16px;
    letter-spacing: 1px;
    margin-bottom: 10px;
  }
}
@media (max-width:991px){
  .sub_heading br{
    display: none;
  }
  .section {
    padding: 80px 0;
}
.sp_bottom {
  padding-bottom: 80px;
}
.sp_top{
  padding-top: 80px;
}
.section_right{
  padding-left: 0;
  padding-top: 40px;
}
.cmn_btn{
  margin-top: 30px;
}
}
@media (max-width:767px){
  .sub_heading br{
    display: none;
  }
  .cmn_btn span{
    padding: 10px;
  }
  .section {
    padding: 60px 0;
}
.sp_bottom {
  padding-bottom: 60px;
}
.sp_top{
  padding-top: 60px;
}
.sub_heading{
  font-size: 16px;
}
}
/* coman css end */

/* header css Start */
@keyframes fadeInDown {
  0% {
     opacity: 0;
     transform: translateY(-20px);
  }
  100% {
     opacity: 1;
     transform: translateY(0);
  }
}
header{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 11;
  
}
header .logo{
  padding-top: 70px;
  padding-left: 70px;
}
.logo a{
  display: inline-block;
}
.logo a img{
 max-width: 350px;
 height: auto;
}
header.headerSticky{
  background: var(--white);
  -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  -ms-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  -o-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  animation-name: fadeInDown;
  animation-duration: .5s;
}
header.headerSticky .logo{
  padding-top:10px;
  padding-bottom: 10px;
}
header.headerSticky .logo a img{
  width: auto;
  height: 50px;
}
.header_gap{
  padding-top: 161px;
}
@media (max-width:1600px) {
  .logo a img{
    max-width: 220px;
  }
  header .logo{
    padding-top: 20px;
  }
  .header_gap{
    padding-top: 130px;
  }
}
@media (max-width:1199px) {
  .logo a img{
    max-width: 200px;
  }
  header .logo{
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .header_gap{
    padding-top: 110px;
  }
}
@media (max-width:991px){
  .logo a img{
    max-width: 194px;
  }
  header{
    position: relative;
  }
  header .container{
    max-width: 100%;
  }
  header .logo{
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 15px;
  }
  .header_gap{
    padding-top: 60px;
  }
}
@media (max-width:767px){
  .logo a img{
    max-width: 154px;
  }

}
/* header css End */



/* what we do section css start */
.wwd_left{
  margin-top: 35px;
}

.whats_app{
  display: inline-block;
  margin-top: 20px;
}

.mTop{
  margin-top: 35px;
}
 .www_box{
  margin-bottom: 35px;
}
.www_box .ind_excerpt p br{
  display: none;
}
 .www_box .sb_icon{
  margin-bottom: 15px;
}
.www_box a{
  color: var(--txt1);
}
.www_box a:hover{
  color: var(--darkpink);
}
.www_box a:hover h3{
  color: var(--darkpink);
}
.www_box p{
  /* display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;  
  overflow: hidden; */

}
.www_box p span{
  color: var(--lightpink);
  font-weight: 600;
}
.whatwedo_btns{
  display: flex;
  flex-wrap: wrap;
  gap: 25px;
  align-items: center;
}
.whatwedo_btns .cmn_btn {
  margin-top: 20px;
}

.border_top{
  position: relative;
}
.border_top::before{
  content: "";
  position: absolute;
  top: 0;
  height: 1px;
  background-color: #D8D8D8;
  left: 70px;
  opacity: 0.2;
  width: calc(100% - 140px);

}
.single_work_page .sb_list,
.single_service_page .sb_list{
  margin-top: 70px;
}
.sb_right{
  position: relative;
  height: 100%;
  padding-left: 76px;
}
.sb_right p{
  margin-top: 0;
}
.sb_right p span{
  color: var(--golden);
  font-weight: 700;
}
.sb_right::before{
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: calc(100% - 20px);
  border-left: 1px solid var(--darkpink);
}

@media (max-width:1199px){
  .sb_right{
    padding-left: 40px;
  }
}
@media (max-width:991px){
  .wwd_left{
    margin-top: 0;
  }
  .www_box {
    margin-bottom: 0;
    margin-top: 40px;
  }
  .single_work_page .sb_list,
  .single_service_page .sb_list{
    margin-top: 0;
  }
  .sb_right::before{
    height: 100%;
  }
}
@media (max-width:767px){
  .sb_right{
    padding-left: 15px;
  }
}
/* what we do section css end */

/* brand section css start */
.theme_gradiunt{
  background: linear-gradient(305deg, #F70B7F 0%, #9A249C 53%, #4F0DCF 100%);
}
.theme_black{
  background-color: var(--txt1);
}
.theme_gray{
  background-color: var(--bg1);
}


.brand_logos{
  margin-top: 57px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 86px;
  align-items: center;
}
.brand_logos .brand_logo{
 vertical-align: middle;
}
.brand_logos .brand_logo img{
  width: 100%;
  height: auto;
}
.brand{
  /* background-image: url('/images/brand_banner.jpg'); */
  background-size: 60%  100%;
  object-fit: cover;
  background-position: right 70px;
  background-repeat: no-repeat;
  padding: 70px 0;
}
.brend_left{
  width: 60%;
  margin-top: -70px;
  padding: 124px 35px 188px 35px;
}
.experince{
  /* background-image: url('/images/experience.jpg'); */
  background-size: 60% 100%;
  object-fit: cover;
  background-repeat: no-repeat;
  padding: 70px 0;
  display: flex;
  align-items: center;
}
.experince .exp_right{
  height: 100%;
  width: 60%;
  margin-left: auto;
  padding: 124px 35px 235px 35px;
}
@media (max-width:1600px){
  .brand_logos{
    margin-top: 30px;
    gap: 50px;
  }
  .experince .exp_right{
    padding: 125px 35px;
  }
  .brend_left{
    padding: 125px 35px;
  }
}
@media (max-width:1199px){
  .experince{
    background-size: 50% 100%;
  }
  .experince .exp_right{
    padding: 60px 35px;
    width: 70%;
  }
  .brend_left{
    padding: 60px 35px;
  }
 
}
@media (max-width:991px){
  .experince{
    background-size: 100%;
    padding-top: 70%;
    padding-bottom: 0;
  }
  .experince .exp_right{
    width: 96%;
    margin: 0 auto;
    padding: 60px 15px;
  }
  .brand{
    background-size: 100%;
    background-position: unset;
    padding-top: 55%;
  }
  .brend_left{
    padding: 60px 15px;
    margin-top: 0;
    width: 96%;
    margin: 0 auto;
  }
}
@media (max-width:767px){
  .brand_logos{
    gap:30px;
  }
}

/* brand section css end */

/* Recognition section css start */
.slider_main{
  margin-top: 80px;
}
.slider_main .item{
  padding: 0 40px;
  height: 120px;
  display: flex !important;
  align-items: center;
  justify-content: center;
}
.slider_main .item img{
  height: auto;
  width: auto;
  max-height: 100%;
}
.slider_main .item:focus-visible{
  outline: none;
  border: none;
}
.slick-prev:before, .slick-next:before{

  color: var(--darkpink) !important;
}
@media (max-width:1600px){
  .full_banner .left_banner .slider_main .item{
    padding: 0 15px;
  }
  .slider_main .item{
    height: 100px;
  }
}
@media (max-width:991px){
  .slider_main{
    margin-top: 0;
  }
}
@media (max-width:767px){
  .slick-prev{
    left: 15px !important;
    z-index: 1;
  }
  .slick-next{
    right: 15px !important;
  }
  .slider_main .item{
    padding: 15px 0;
  }
}
@media (max-width:600px){
  .slick-prev{
    left: 0px !important;
    z-index: 1;
  }
  .slick-next{
    right: 0px !important;
  }
}
/* Recognition section css end */

/* quote section css start */
.quote{
  text-align: center;
}
/* quote section css end */

/* experience section start */
.exp_menus{
  margin-top: 80px;
}
.exp_menu{
  margin-top: 20px;
}
.exp_menu h3{
  color: var(--white);
  font-size: 28px;
  font-weight: 700;
  line-height: 128.57%;
  margin-bottom: 15px;
}
.exp_menu ul li a{
  position: relative;
  padding-left: 16px;
  font-size: 14px;
  color: var(--white);
  line-height: 171.429%;
  display: inline-block;
}
.exp_menu ul li a:hover{
  color: var(--darkpink);
}
.exp_menu ul li a::before{
  content: "";
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background-color: var(--darkpink);
  position: absolute;
  left: 0;
  top: 8px;
}
.accordion-header{
  font-size: 18px;
  color: var(--txt1);
}
.accordion-button:focus{
  box-shadow: none;
  outline: none;
}
.accordion-button:not(.collapsed){
  background-color: transparent;
  box-shadow: none;
  color: var(--lightpink);

}
.accordion-item{
  border: none;
  border-radius: 0;
  border-bottom: 1px solid #CACACA;
}
.accordion-button{
  padding: 15px 20px 15px 0;
  font-size: 16px;
  font-weight: 600;
}
.accordion-body{
  padding: 15px 0;
}
.accordion-body p{
  font-size: 16px;
  margin-bottom: 20px;
  margin-top: 0;
}
.accordion-body p:last-child{
  margin-bottom: 0;
}
.accordion-item:first-of-type,
.accordion-item:last-of-type{
  border-radius: 0;
}
.accordion-button::after{
  background-image: url('/images/accordian_arrow.svg');
  background-size: 13px;
  position: absolute;
  right: 0;
  margin: 0;
  height: 9px;
  width: 13px;
}
.accordion-button:not(.collapsed)::after{
  background-image: url('/images/accordian_arrow.svg');
  background-size: 13px;
  transform: rotate(180deg);
}
@media (max-width:1600px){
  .exp_menus {
    margin-top: 60px;
}
.exp_menu h3{
  font-size: 26px;
}
}
@media (max-width:1199px){
  .exp_menus {
    margin-top: 40px;
}
.exp_menu h3{
  font-size: 24px;
}
}
@media (max-width:991px){
  .exp_menus {
    margin-top: 20px;
}
.exp_menu h3{
  font-size: 20px;
}
}
@media (max-width:767px){
  .accordion-body p{
    font-size: 14px;
  }
.exp_menu h3{
  font-size: 18px;
}
.accordion-header{
  font-size: 16px;
}
.accordion-button{
  padding: 10px 20px 10px 0;
}
}
/* experience section end */

/* insight box section css start */
.insight_form form{
  display: flex;
  gap: 10px;
  max-width: 90%;
}

.insight_form p{
  color: rgba(29, 37, 44, 0.50);
  font-size: 14px;
  font-weight: 400;
  line-height: 128.571%;
  margin-top: 6px;
}
@media (max-width:1400px){
  .insight_form form{
    max-width: 100%;
  }
  .insight_form form{
    gap: 5px;
  }

}
@media (max-width:1199px){
  .insight_form form{
    gap: 10px;
  }
  .insight_form form{
    flex-wrap: wrap;
  }
  .insight_form form .input_wraper{
    flex: calc(50% - 10px);
  }
}
@media (max-width:767px){
  .insight_form form {
    flex-wrap: wrap;
  }
  .insight_form form .input_wraper:nth-child(3),
  .insight_form form .input_wraper{
    flex-grow: unset;
    width: 100%;
  }
}
@media (max-width:575px){
  .insight_form form .input_wraper{
    flex: 100%;
  }
}
/* insight box section css end */

/* wd insight section css start */
.wd_box{

}
.wd_box .wd_img{
  position: relative;
  padding-bottom: 56.2%;
  display: block;
  overflow: hidden;
}
.wd_box .wd_img::before{
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  background-color: rgba(120, 84, 100, .5);
}
.wd_box .wd_img img{
  position: absolute;
  object-fit: cover;
  width: 100%;
  height: 100%;
  transition: all 0.5s;
}
.wd_box:hover .wd_img img{
  transform: scale(1.1);
}
.wd_box:hover .wd_img::before{
  z-index: 1;
}
.wd_content {
  text-align: center;
  padding: 0 20px;
}
.blogs_cats{
  display: flex;
  justify-content: center;
}
.blogs_cats ul{
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  margin-top: 26px;
  padding-right: 10px;
}
.blogs_cats ul li a{
  color: rgba(29, 37, 44, 0.50);
  font-size: 16px;
  font-weight: 600;
  line-height: 150%;
  display: block;
  position: relative;
  padding: 0 5px;
}
.blogs_cats ul li a::after{
  content: "-";
  position: absolute;
  right: -5px;
}
.blogs_cats ul li:last-child a::after{
  display: none;
}
.wd_content span{
  color: rgba(29, 37, 44, 0.50);
    padding: 0 15px;
  font-size: 16px;
  font-weight: 600;
  line-height: 150%;
  min-width: 100px;
  margin-top: 26px;
  position: relative;
}
.wd_content span::before{
  content: "";
  width: 2px;
  background-color: rgba(29, 37, 44, 0.50);
  height: 15px;
  position: absolute;
  left: 0;
  top: 5px;
}
.wd_content a,
.wd_content a h3{
  line-height:   133.333%;
  margin: 0;
}
.wd_content a:hover h3{
  color: var(--darkpink);
}
.wd_top .col-lg-4{
  margin-top: 70px;
}
@media (max-width:767px){
  .wd_content{
    padding: 0 10px;
  }
  .blogs_cats{
    flex-wrap: wrap;

  }
  .blogs_cats ul{
    justify-content: center;
  }
  .wd_top .col-lg-4{
    margin-top: 30px;
  }
  .wd_content span,
  .blogs_cats ul li a{
    font-size: 14px;
  }
  .wd_content span{
    margin-top: 10px;
    display: block;
    width: 100%;
  }
}
/* wd insight section css end */

/* start a project section css start */
.sp_content{
  padding: 60px 0;
}
.shape_bg{
  margin-top: -35px;
  height: calc(100% + 70px);
  background-image: url('/images/shape.svg');
  background-size: 100% 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.shape_bg img{
  max-width: 100%;
}

/* start a project section css end */

/* Footer section css start */
.footer{
  padding: 40px 0 0 0;
}
.footer_wrap{
  border-bottom: 1px solid rgba(29, 37, 44, 0.15);
  padding-bottom: 60px;
}
.f1 p{
  font-size: 14px;
}
.flogo a{
  display: block;
}
.flogo img{
  max-width: 75%;
  height: auto;
}
.footer_social ul{
  display: flex;
  align-items: center;
  gap: 15px;
  margin-top: 35px;
}
.footer_social ul li a{
  font-size: 22px;
  display: block;
  color: var(--txt1);
}
.footer_social ul li a:hover{
  color: var(--darkpink);
}
.f2{
  padding: 0 30px;
  margin-top: 50px;
}
.f2 h3{
  font-weight: 700;
  font-size: 18px;
  color: var(--txt1);

}
.f2 .f_menus{
  border-top: 1px solid rgba(29, 37, 44, 0.15);
  display: flex;
  justify-content: space-between;
}
.f2 .f_menus ul{
  margin-top: 20px;
}
.f2 .f_menus ul.li50{
  column-count: 2;
  width: 100%;
}

.f2 .f_menus ul li a{
  font-size: 14px;
  font-weight: 400;
  color: var(--txt1);
  line-height:  142.857%;
}
.f2 .f_menus ul li a:hover{
  color: var(--darkpink);
}
.f2 .cmn_btn{
  width: 100%;
  margin-top: 0;
}
.f3{
  padding-right: 0;
}
.f3 h3{
  margin-top: 20px;
}
.f3 p{
  font-size: 14px;
  line-height:  142.857%;
}
.f3 a{
  color: var(--txt1);
  font-size: 14px;
  font-weight: 400;
  display: inline-block;
  margin-top: 10px;
}
.f3 a:hover{
  color: var(--darkpink);
}
.copy_right{
  padding: 40px 0;
}
.copy_right p{
    margin: 0;
    color: rgba(29, 37, 44, 0.70);
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height:  142.857% ;
}
.copy_right p a{
  color: rgba(29, 37, 44, 0.70);
}
.copy_right p a:hover{
  color: var(--darkpink);
}
@media (max-width:1199px){
  .f2{
    padding: 0;
    margin-top: 40px;
  }
}

@media (max-width:767px){
  .copy_right{
    padding: 15px 0;
  }
  .f2 .f_menus ul.li50{
    column-count: 1;
    width: auto;
  }
}
/* Footer section css end */

/* sidebar css start */
.sidebar {
  width: 410px;
  height: 100%;
  position: fixed;
  top: 0;
  right: -410px; /* Place the sidebar on the right side */
  background: #333;
  transition: right 0.3s; /* Animate the right position */
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  z-index: 99;
}

.sidebar.open {
  right: 0; /* Open the sidebar by setting right to 0 */
}

.toggle-btn {
  position: fixed;
  top: 0;
  right: 0; 
  background: var(--blue);
  border: none;
  width: 70px;
  height: 70px;
  color: var(--white);
  cursor: pointer;
  padding: 5px;
  font-size: 26px;
  z-index: 999;
  border: none;
  transition: all 0.5s;
}
/* .sidebar.open .toggle-btn{
  background-color: transparent;
} */
.sidebar_wrap{
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
}
.side_head{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 70px 20px 10px;
}
.side_head a.call{
  color: var(--white);
  font-size: 16px;
  font-weight: 600;
}
.side_head a.call:hover{
  color: var(--lightpink);
}
.side_head > a svg{
  color: var(--lightpink);
  margin-right: 8px;
}
.side_head .cmn_btn_fill{
  margin-top: 0;
  border-radius: 0;
  height: 70px;
  line-height: 70px;
  padding: 0 30px;
}
.sidebar_menu{
  padding: 20px 70px 20px 30px; 
}
.sidebar_menu ul{
  position: relative;
  padding-bottom: 20px;
}
.sidebar_menu ul::after{
  content: "";
  position: absolute;
  bottom: 0;
  right: 0;
  width: 40px;
  height: 1px;
  opacity: .4;
  background-color: #fff;
}
.sidebar_menu{
  display: flex;
  justify-content: flex-end;
}
.sidebar_menu ul li{
  position: relative;
  -webkit-transform: translateX(-5rem);
    transform: translateX(-5rem);
    opacity: 0;
    -webkit-transition: opacity 0.55s,-webkit-transform 0.55s;
    transition: opacity 0.55s,-webkit-transform 0.55s;
    transition: opacity 0.55s,transform 0.55s;
    transition: opacity 0.55s,transform 0.55s,-webkit-transform 0.55s;
    margin-bottom: 20px;
}
.sidebar.open .sidebar_menu ul li {
  opacity: 1;
  -webkit-transform: translateX(0);
  transform: translateX(0);
 
}
.sidebar.open .sidebar_menu ul li:first-of-type {
  -webkit-transition-delay: 0.1s;
  transition-delay: 0.1s;
}
.sidebar.open .sidebar_menu ul li:nth-of-type(2) {
  -webkit-transition-delay: 0.2s;
  transition-delay: 0.2s;
}
.sidebar.open .sidebar_menu ul li:nth-of-type(3) {
  -webkit-transition-delay: 0.3s;
  transition-delay: 0.3s;
}
.sidebar.open .sidebar_menu ul li:nth-of-type(4) {
  -webkit-transition-delay: 0.4s;
  transition-delay: 0.4s;
}
.sidebar.open .sidebar_menu ul li:nth-of-type(5) {
  -webkit-transition-delay: 0.5s;
  transition-delay: 0.5s;
}
.sidebar.open .sidebar_menu ul li:nth-of-type(6) {
  -webkit-transition-delay: 0.6s;
  transition-delay: 0.6s;
}
.sidebar.open .sidebar_menu ul li:nth-of-type(7) {
  -webkit-transition-delay: 0.7s;
  transition-delay: 0.7s;
}
.sidebar.open .sidebar_menu ul li:nth-of-type(8) {
  -webkit-transition-delay: 0.8s;
  transition-delay: 0.8s;
}
.sidebar.open .sidebar_menu ul li:nth-of-type(9) {
  -webkit-transition-delay: 0.9s;
  transition-delay: 0.9s;
}
.sidebar.open .sidebar_menu ul li:nth-of-type(10) {
  -webkit-transition-delay: 1s;
  transition-delay: 1s;
}
.sidebar_menu ul li a{
  display: inline-block;
  width: 100%;

  color: var(--white);
  font-size: 24px;
  font-weight: 400;
  text-transform: capitalize;
  position: relative;
  transition: all 0.5s;
}
.sidebar_menu ul li a:hover{
  color: var(--lightpink);
}

.sidebar_social{
    padding: 20px 70px 20px 30px; 
}
.sidebar_social ul{
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  justify-content: flex-end;
}
.sidebar_social ul li a{
  color: var(--white);
  font-size: 24px;
}
.sidebar_social ul li a:hover{
  color: var(--lightpink);
}
@media (max-width:767px) {
  .toggle-btn{
    width: 60px;
    height: 60px;
    right: 0;
  }
  .side_head{
    flex-wrap: wrap;
    justify-content: flex-end;
    gap: 20px;
  }
  .sidebar {
    width: 280px;
    right: -280px;
  }
  .side_head,
  .sidebar_menu,
  .sidebar_social{
    padding: 20px 70px 20px 20px; 
  }
  .sidebar_menu ul li a{
    font-size: 20px;
  }
  .sidebar_menu ul li{
    margin-bottom: 15px;
  }
  .side_head .cmn_btn_fill {
    height: 50px;
    line-height: 50px;
    padding: 0 20px;
}
.sidebar_social ul{
  gap: 15px;
}
.sidebar_social ul li a{
  font-size: 18px;
}
}
@media (max-width:280px){
  .sidebar {
    width: 260px;
    right: -260px;
}
}
/* sidebar css end */

/* testimonial section css start */
.testimonial h2{
  color: rgba(255, 255, 255, 0.50);
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 122.222%;
  letter-spacing: 4.5px;
  text-transform: uppercase;
}
.testi_brand_logos{
    margin-top: 50px;
}
.testi_brand_logos .brand_logo{
  padding: 0 10px;
  text-align: center;
  vertical-align: middle;
}
.testi_brand_logos .brand_logo img{
  height: auto;
}
.testi_brand_logos .slick-slide div:focus-visible{
  outline: none !important;
  box-shadow: none !important;
  border: none !important;
}
.testi_brand_logos .slick-track{
    display: flex !important;
}
.testi_brand_logos .slick-slide{
    height: inherit !important;
    display: flex !important;
    justify-content: center;
    align-items: center;
}
.testi_slideer{
  max-width: 950px;
  margin: 30px auto 0 auto;
  padding-bottom: 40px;
}
.testi_slideer .item{
  text-align: center;
}
.testi_slideer .item p{
  color: var(--white);

  font-size: 32px;
  font-weight: 400;
  font-style: italic;
}

.client_details{
  margin-top: 25px;
}
.client_details h3,
.client_details span{
  color: var(--txt1);
  font-size: 16px;
}
.white.client_details h3,
.white.client_details span{
  color: var(--white);
}
.client_details  h3{
 font-weight: 600;
 margin-bottom: 0;
}
.seperator{
  text-align: center;
  display: flex;
  justify-content: center;
  margin: 20px 0;
}
.seperator span{
  width: 48px;
  height: 2px;
  display: inline-block;
  background: var(--darkpink);
}
.testi_slideer .slick-slider{
  padding-bottom: 50px;
}
.slick-dots{
  bottom: 0 !important;
}
.slick-dots li{
  margin: 0 6px !important;
}
.slick-dots li button{
  padding: 0 !important;
 
}
.slick-dots li button:before{
  font-size: 18px !important;
  /* color: rgba(255, 255, 255, 0.7) !important; */
  color: rgb(125 119 119 / 70%) !important;
}
.slick-dots li.slick-active button:before{
  color: var(--darkpink) !important;
  opacity: 1 !important;
}
@media (max-width:1600px) {
  .testi_slideer .item p{
    font-size: 24px;
  }
}
@media (max-width:1199px) {
  .testi_slideer .item p{
    font-size: 20px;
  }
  .testi_brand_logos{
    grid-template-columns: auto auto auto;
    grid-row-gap: 30px;
  }
}
@media (max-width:767px) {
  .testi_brand_logos{
    grid-column-gap: 30px;
    grid-row-gap: 30px;
  }
  .testi_slideer .item p{
    font-size: 18px;
  }
}
/* testimonial section css end */

/* since section css start */

.since_left{
  background: linear-gradient(311deg, #F70B7F 0%, #9A249C 53%, #4F0DCF 100%);
  width: 100%;
  padding: 100px 70px;
}
.since_content h1,
.since_content h2,
.since_content h3{
  font-weight: 300;
}

.since_left p{
  font-size: 18px;
}
.since_right{
  height: 100%;
}
.since_list{height: 100%;}
.since_list{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  row-gap: 50px;
}
.since_box{
  width: 50%;
  height: calc(50% - 25px);
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.since_box h3{
  text-align: center;
  font-size: 72px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 0;
}
.since_box  p{
    margin: 0;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.since_list .since_box:nth-child(4n - 1),
.since_list .since_box:nth-child(4n - 2){
  background-color: var(--blue);
}
.since_list .since_box:nth-child(4n - 1) h3,
.since_list .since_box:nth-child(4n - 2) h3,
.since_list .since_box:nth-child(4n - 1) p,
.since_list .since_box:nth-child(4n - 2) p{
  color: var(--white);
}
@media (max-width:1600px) {
  .since_box h3{
    font-size: 62px;
  }
  .since_box p{
    font-size: 20px;
  }
}
@media (max-width:1199px) {
  .since_box h3{
    font-size: 52px;
  }
  .since_box p{
    font-size: 18px;
  }
}
@media (max-width:991px) {
  .since_box h3{
    font-size: 42px;
  }
  .since_box p{
    font-size: 16px;
  }
  .since_left{
    padding: 80px 15px;
  }
  .since_list{
    row-gap: 0;
  }
  .since_box{
    height: auto;
    padding: 20px;
  }
  .since_left p{
    font-size: 16px;
  }
}

@media (max-width:767px) {
  .since_box{
    padding: 15px;
  }
  .since_box h3{
    font-size: 32px;
  }
  .since_left{
    padding: 60px 15px;
  }
  .since_left p{
    font-size: 14px;
  }
}
/* since section css end */

/* meet director section css start */
.md_list{
  display: flex;
  gap: 42px;
}
.md_list .md_box{
  width: 50%;
  text-align: center;
}
.md_list .md_box .md_img{
  padding-bottom: 120%;
  position: relative;
}
.md_list .md_box .md_img img{
  position: absolute;
  left: 0;
  object-fit: cover;
  top: 0;
  width: 100%;
  height: 100%;
}
.md_list .md_box span{
  display: block;
  margin-top: 9px;
  font-weight: 400;
  text-transform: capitalize;
}
@media (max-width:991px){
  .md_list{
    margin-top: 40px;
  }
  .md_list{
    gap: 30px;
  }
}
@media (max-width:767px){
  .md_list{
    gap: 10px;
  }
}
/* meet director section css end */

/* client Says Section Start */
.clogo_list{
  display: grid;
  align-items: center;
  grid-template-columns: auto auto auto auto;
  grid-column-gap: 80px;
  grid-row-gap: 80px;
}
.clog1{
  height: 100%;
  display: flex;
  align-items: center;

}
.clog1 img{
  width: 100%;
  height: auto;
}
/* client Says Section end */

/* about page css start */
.aboutus_page .whatwedo{
  padding-bottom: 70px;
  padding-top: 0;
}
.aboutus_page .full_banner .left_banner{
  padding-bottom: 30px;
}
.aboutus_page .full_banner .right_banner .right_img::before{
  height: calc(100% - 70px);
  top: 70px;
}
.white_link{
  color: var(--white);
}
@media (max-width:991px){
  .aboutus_page .full_banner .right_banner .right_img::before{
    top: 0;
    height: 100%;
  }
}
/* about page css end/

/* contact us Billboard css start */
.bcontact_info{
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 30px;
  margin-top: 20px;
}
.bcontact_info .cmn_btn{
  margin-top: 0 !important;
}
.bcontact_info .whats_app{
  margin-top: 0;
}
.bcontact_info p{
  margin: 0;
  color: var(--txt1);
  font-size: 16px;
  font-weight: 400;
}
.bcontact_info p a{
  color: #F70B7F;
}
.full_banner .left_banner .slider_main .item{
  padding: 0 25px;
 
}
.full_banner .left_banner .slider_main .item img{
  width: auto;
}
.full_banner .left_banner .slider_main{
  margin-top: 40px;
}

.full_banner .left_banner .slider_main .slick-prev{
  left: 0 !important;
}
.full_banner .left_banner .slider_main .slick-next{
  right: 0 !important;
}
.full_banner .left_banner .slider_main .slick-dots{
  bottom: -30px !important;
}
.c_form .row .col-lg-6{
  padding: 0 10px;
}
.c_form {
  margin-top: 40px;
}
.c_form .input_wraper{
  position: relative;
  margin-bottom: 20px;
}
.button_wraper{
  margin-top: 60px;
  display: flex;
  align-items: center;
  gap: 20px;
}
.c_form textarea ~ label,
.c_form select ~ label,
.c_form input ~ label{
  color: rgba(255, 255, 255, 0.70);
  font-size: 10px;
  padding-left: 9px;
  width: 100%;
  position: absolute;
  left: 0;
  top: 9px;
  transition: 0.3s; 
}
p.error{
  margin-top: 5px;
  color:var(--white);
  font-size: 14px;
}
p.error_red{
  color: red;
  margin-top: 5px;
  font-size: 14px;
}
.success_message{
    display: flex;
    align-items: center;
    margin-top: 10px;
    font-size: 14px;
}
.c_form textarea:focus ~ label,
.c_form select:focus ~ label,
.c_form input:focus ~ label{
  top: -17px;
}
.c_form input[type="tel"],
.c_form input[type="text"],
.c_form input[type="email"],
.c_form input[type="number"]{
  background: rgba(255, 255, 255, 0.08);
  color: var(--white);
  border: none;
  padding: 9px;
  font-size: 14px;
  padding-right: 9px;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}
.c_form .input_wraper select{
  width: 100%;
  height: 60px;
 padding: 9px;
  background: rgba(255, 255, 255, 0.08);
  color: var(--white);
  border: none;
  font-size: 14px;
  font-weight: 400;

}
.c_form .input_wraper select option{
  color: var(--txt1);
}
.c_form .input_wraper select:focus{
  border: none;
  box-shadow: none;
  outline: none;
}
.c_form .input_wraper textarea{
  background: rgba(255, 255, 255, 0.08);
  padding: 9px;
  color: var(--white);
  border: none;
  height: 160px;
  width: 100%;
  font-size: 14px;
  padding-top: 27px;
}
.c_form .input_wraper input[type="file"]{
  /* border: 1px solid var(--white); */
  width: 100%;
  border: none;
  background-color: transparent;
  font-size: 14px;
  color: var(--white);
  cursor: pointer;
}
.freeConsultancy_page .full_banner,
.contact_page .full_banner{
  min-height: unset;
}
.freeConsultancy_page .full_banner .left_banner,
.contact_page .full_banner .left_banner{
  height: unset;
}
.freeConsultancy_page .full_banner .right_banner.full_bg,
.contact_page .full_banner .right_banner.full_bg{
  padding: 70px;
  height: calc(100% + 70px);
  width: calc(100% + 70px);
  margin-bottom: -70px;
  position: relative;
  left: unset;
}
@media (max-width:1600px){
  .clogo_list {
    grid-template-columns: auto auto auto;
  }
}
@media (max-width:1199px){
  .c_form .input_wraper textarea{
    height: 100px;
  }
  .full_banner .left_banner .slider_main{
    margin-top: 20px;
  }
  .full_banner .left_banner .slider_main  .item{
    height: 80px;
  }
  .freeConsultancy_page .full_banner .right_banner.full_bg, 
  .contact_page .full_banner .right_banner.full_bg {
    padding: 70px;
    height: auto;
    margin-bottom: -30px;
    width: 100%;
  }
  .full_banner.full_1199  .right_banner{
    position: relative;
    left: unset;
    width: 100%;
    height: auto;
  }
  .freeConsultancy_page .full_banner .left_banner, 
  .contact_page .full_banner .left_banner {
    padding-top: 120px;
    padding-bottom: 40px;
}
}
@media (max-width:991px){
  .clogo_list {
    grid-template-columns: auto auto auto;
    grid-column-gap: 40px;
    grid-row-gap: 40px;
  }
  .freeConsultancy_page .full_banner .right_banner.full_bg,
  .contact_page .full_banner .right_banner.full_bg{
 
    padding: 40px 30px;
  }
  .freeConsultancy_page .full_banner .left_banner, 
  .contact_page .full_banner .left_banner {
    padding-top:0;
    padding-bottom: 0;
}
 
}
@media (max-width:767px){
  .clogo_list {
    grid-template-columns: auto auto auto;
    grid-column-gap: 30px;
    grid-row-gap: 30px;
  }
  .freeConsultancy_page .full_banner .right_banner.full_bg,
  .contact_page .full_banner .right_banner.full_bg{
    padding: 40px 15px;
  }
}
/* contact us Billboard css end */

/* single service page css start */
.full_banner{
  min-height: 100vh;
}
 .container-fluid{
  padding: 0 70px;
}
.full_banner .left_banner{
  height: 100vh;
  display: flex;
  flex-direction: column;
  position: relative;
}

.full_banner .left_banner .caption{
  margin: auto 0;
}
 .caption h1{font-size: 34px;}
.full_banner .right_banner{
  position: absolute;
  left: 42%;
  width: 58%;
  height: 100%;
  padding-left: 24px;
}
.full_banner .left_banner .caption .whats_app_btn{
  position: absolute;
  bottom: 20px;
}
.full_banner .right_banner .right_img{
  height: 100%;
  width: 100%;
  position: relative;
  padding-top: 70px;
  padding-right: 70px;
  padding-bottom: 70px;
}
.full_banner .right_banner.full_bg{
  background: linear-gradient(318deg, #F70B7F 0%, #9A249C 53%, #4F0DCF 100%);
}
.full_banner .right_banner .right_img::before{
  content: "";
  position: absolute;
  right: 0;
  top: 0;
  width: calc(100% - 70px);
  height: 100%  ;
  background: linear-gradient(317deg, #F70B7F 0%, #9A249C 53%, #4F0DCF 100%);
}
.home_page .full_banner .right_banner .right_img::before{
  background: linear-gradient(95deg, #FFF 17.29%, #F70B7F 56.41%, #C63181 73.74%, #F70B7F 73.75%, #4F0DCF 100.01%);
  mix-blend-mode: darken;
  width: calc(100% + 25%);
}
.full_banner .right_banner .right_img img{ 
  height: 100%;
  width: 100%;
  position: relative;
  object-fit: cover;
}
@media (max-width:1600px){
  .full_banner .left_banner .caption{
    padding-top: 77px;
  }
 .caption h1{font-size: 32px; margin-bottom: 20px;}

  .caption p{
    margin-top: 15px;
  }
  .caption .cmn_btn{
    margin-top: 20px;
  }
  .full_banner .left_banner .slider_main{
    margin-top: 20px;
  }
}
@media (max-width:1199px) {
  .full_banner .left_banner .cmn_btn{
    margin-top: 30px;
  }
 .caption h1{font-size: 30px;}
  .whats_app  img{
    max-width: 150px;
    height: auto;
  }
}
@media (max-width:991px) {
  .full_banner {
    min-height: unset;
  }
 .container-fluid {
    padding: 0 15px;
  }
  .full_banner .left_banner{
    height: unset;
    
  }

  .full_banner .left_banner .caption{
    padding: 40px 0;
  }
  .caption h1{font-size: 28px;}
  .full_banner .left_banner .caption .whats_app_btn{
    position: relative;
    margin-top: 20px;
  }
  .full_banner .right_banner{
    position: relative;
    left: 0;
    width: 100%;
    padding-left: 0px;
  }
  .full_banner .right_banner .right_img{
   padding-top: 0;
  }
}
@media (max-width:767px){
  .full_banner .right_banner .right_img{
    padding-right: 15px;
    padding-bottom: 15px;
  }
  .caption h1{font-size: 20px;}
}
/* single service page css end */

/* image testimonial section css start */
.single_work_page .img_testimonial .iTestiBox,
.single_service_page .img_testimonial .iTestiBox{
  margin-top: -250px;
  position: relative;
}
.single_work_page .testimonial,
.single_service_page .testimonial{
  padding-bottom: 350px;
}
.iTestiBox{
  display: flex;
  align-items: center;
}
.iTestiBox .iTesti_img{
  width: 490px;
  height: 490px;
  padding-left: 40px;
  padding-bottom: 40px;
  position: relative;
}

.iTestiBox .iTesti_img::before{
  content: "";
  position: absolute;
  left: 0;
  width: calc(100% - 40px);
  top: 40px;
  height: calc(100% - 40px);
  background: linear-gradient(307deg, #F70B7F 3%, #9A249C 59%, #4F0DCF 97%);

}
.iTestiBox .iTesti_img img{
  width: 100%;
  position: relative;
  object-fit: cover;
  height: 100%;
}


.iTestiBox  .iTestContent{
  width: calc(100% - 490px);
  height: calc(100% - 100px);
  background-color: var(--white);
  padding: 50px;
}
.iTestiBox  .iTestContent p{
  font-size: 30px;
  font-style: italic;
  font-weight: 600;
  line-height: 146.667%;
  margin: 0;
}
.iTestiBox  .iTestInfo{
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 30px;
  margin-top: 25px;
}
.brand_logo {
  max-width: 333px;
}
.brand_logo img{
  max-width: 100%;
  height: auto;
}
.iTestiBox  .iTestInfo .client_details{
  margin-top: 0 ;
}
/* image testimonial section css end */

/* zigzag1 section css start */
.zigzag1{
  min-height: 100vh;
}
.zigzag1 .z1_left .accordion-button{
  font-weight: 600;
}
.accordion-button .a_number{
  color: var(--blue);
  display: inline-block;
  margin-right: 15px;
}

.zigzag1  .z1_right .z1_img{
    margin-right: -70px;
    padding-left: 70px;
}
.zigzag1  .z1_right .z1_img img{
  width: 100%;
  height: auto;
}

.without_screen_img{
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  height: 100%;
  width: calc(100% + 150px);
}
.zig50 .without_screen_img{
  padding-left: 70px;
}
.zig50.reverse .without_screen_img{
  left: -150px;
  padding-right: 70px;
  padding-left: unset;
}

.zig50 .without_screen_img img{
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.zig50 .without_screen_img img{
  object-position: right center;
}
.zig50.reverse .without_screen_img img{
  object-position: left center;
}
@media (max-width:1600px){
  .iTestiBox .iTestContent p{
    font-size: 24px;
  }
  .iTestiBox .iTestContent{
    padding: 30px;
  }
  .iTestiBox .iTesti_img{
    width: 390px;
    height: 390px;
  }
  .iTestiBox .iTestContent{
    width: calc(100% - 390px);
  }
  .without_screen_img{
    width: calc(100% + 100px);
  }
  .zig50.reverse .without_screen_img{
    left: -100px;
  }
  
}
@media (max-width:991px){
  .iTestiBox{
    flex-wrap: wrap;
  }
  .iTestiBox .iTesti_img{
    width: 100%;
    height: 100%;
  }
  .iTestiBox .iTestContent{
    width: 100%;
    padding: 15px 0 0 0;
  }
  .iTestiBox .iTestContent p{
    font-size: 20px;
  }
  .zigzag1{
    min-height: auto;
  }
  .z1_right{
    padding-top: 40px;
  }
  .zigzag1 .z1_right .z1_img{
    margin-right: 0;
    padding-left: 0;

  }
  .zig50 .without_screen_img,
  .zig50.reverse .without_screen_img{
    top: unset;
    right: unset;
    position: relative;
    bottom: -50px;
    transform: unset;
    height: 100%;
    width: 100%;
    padding: 0 15px;
    left: unset;
  }
  .zig50 .without_screen_img img{
    object-position: center bottom;
  }
  .zig50.reverse .without_screen_img img{
    object-position: center bottom;
  }
  .without_screen .zig50_content{
    padding-top: 30px;
  }
}
@media (max-width:767px){
  .iTestiBox .iTesti_img{
    padding-left: 15px;
    padding-bottom: 15px;
  }
  .single_work_page .testimonial,
  .single_service_page .testimonial{
    padding-bottom: 290px;
  }
  .iTestiBox .iTestContent p{
    font-size: 18px;
  }
  .iTestiBox .iTestInfo{
    gap: 15px;
  }
  .brand_logo{
    max-width: 180px;
  }
}
/* zigzag1 section css end */

.zig50{
  height: 100vh;
  display: flex;
}
.zig50.reverse{
  flex-direction: row-reverse;
}
.zig50.reverse .zig50_content{
  padding-left: 70px;
  padding-right: 260px;
}
.zig50.reverse .zig50_center{
  left: -260px;
}
.zig50.reverse  .laptop #macScreen {
  position: absolute;
  top: 50%;
  left: 0;
  width: 76%;
  padding-top: 50%;
  transform: translate(16%, -60%);
}
.zig50 .zig50_left{
    height: 100%;
    width: 50%;
    /* background-image: url('/images/zig-bg.jpg'); */
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
}
.zig50 .zig50_right{
  height: 100%;
  width: 50%;
  background: #F3F4F4;
  padding: 15px;
}
.zig50_center{
  position: absolute;
  top: 50%;
  transform: translateY(50%);
  left: 0;
  width: calc(100% + 260px);
  /* height: calc(100% - 200px); */
}
.nImg{
    position: relative;
    display: flex;
    height: 100%;
    align-items: center;
    width: calc(100% + 20%);
    padding-top: 120px;
    padding-bottom: 120px;
    padding-left: 70px;
}
.nImg img{
  height: auto;
  border-radius: 30px;
}
.laptop{
  position: relative;
}

.laptop #macScreen {
  position: absolute;
  top: 50%;
  left: 0;
  width: 88%;
  padding-top: 60%;
  transform: translate(4%, -50%);
  background-size: cover;
  z-index: 15;
  z-index: 0;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  background-image: url('/images/zig_content_img.jpg');
}
.laptop img {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: auto;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: 1;
}
.zig50_content{
  padding-left: 260px;
  display: flex;
  align-items: center;
  height: 100%;
}
.work_content ul,
ul.list{
  margin-top: 20px;
}
.work_content ul li,
ul.list li{
    font-size: 18px;
    position: relative;
    padding-left: 20px;
    line-height: 150%;
}
.work_content ul li::before,
ul.list li::before{
  content: "";
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background-color: var(--lightpink);
  position: absolute;
  left: 0;
  top: 11px;
}
@media (max-width:1600px){
  .zig50_center{
    width: calc(100% + 150px);
  }
  .zig50_content{
    padding-left: 170px;
  }
  .zig50.reverse .zig50_content {
    padding-right: 170px;
  }
  .zig50.reverse .zig50_center {
    left: -170px;
  }
  .nImg{
   
    padding: 40px 15px 40px 15px;
  }
}
@media (max-width:1199px) {
  .zig50_center {
    width: calc(100% + 100px);
}
  .zig50_content{
    padding-left: 120px;
  }
  .work_content ul li,
  ul.list li{
    font-size: 16px;
  }
  .zig50.reverse .zig50_content {
    padding-right: 120px;
  }
  .zig50.reverse .zig50_center {
    left: -120px;
  }
}
@media (max-width:991px){
  .nImg{
    width: 100%;
  }
  .nImg img{
    border-radius: 15px;
  }
  .zig50{
    height: auto;
    flex-wrap: wrap;
  }
  .zig50 .zig50_left{
    width: 100%;
    height: auto;
  }
  .zig50_center{
    width: 100%;
    padding-bottom: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    top: unset;
    transform: unset;
  }
  .zig50_center .laptop{
    position: unset;
  }
  .zig50 .zig50_right{
    height: auto;
    width: 100%;
    padding: 40px 15px;
  }
  .zig50_content {
    padding-left: 0;
}
.work_content ul li,
ul.list li{
  font-size: 14px;
}
.zig50.reverse .zig50_content {
  padding-right: 0;
  padding-left: 0;
}
.zig50.reverse .zig50_center {
  left: 0;
}

}
/* hero btn css start */
@keyframes slide-down {
  0% {
      -webkit-transform: translateY(-100%);
      transform: translateY(-100%)
  }

  100% {
      -webkit-transform: translateY(100%);
      transform: translateY(100%)
  }
}

@-webkit-keyframes slide-down {
  0% {
      -webkit-transform: translateY(-100%);
      transform: translateY(-100%)
  }

  100% {
      -webkit-transform: translateY(100%);
      transform: translateY(100%)
  }
}

.hero_btn{
  transform: translateY(70px);
  position: relative;
  z-index: 1;
}
.hero_btn .hero_btn_box{
  position: absolute;
  bottom: 0;
  left: 0;
  right: auto;
  width: 70px;
  height: 240px;
  font-size: 18px;
  background-color: var(--blue);
  color: var(--white);
  font-weight: 500;
  border: none;
}
.bar_text{
  -webkit-transform: rotate(-90deg) translateX(40px);
  transform: rotate(-90deg) translateX(40px);
  position: relative;
  display: block;
  text-transform: capitalize;
}
.bar_line{
    bottom: 30px;
    height: 60px;
    position: absolute;
    left: calc(50% - 0px);
    bottom: 40px;
    width: 2px;
    height: 70px;
    transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
}
.bar_line_wrap{
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.bar_line_down{
  height: 60px;
  width: 2px;
  background-color: var(--white);
  display: block;
  animation: slide-down 2s infinite;
  -webkit-animation: slide-down 2s infinite;
}
@media (max-width:1600px) {
  .hero_btn .hero_btn_box{
    width: 50px;
    height: 180px;
    font-size: 16px;
  }
  .bar_line{
    bottom: 20px;
    height: 40px;
  }
}
@media (max-width:1199px) {
  .hero_btn {
    transform: translateY(20px);
  }
  .bar_text{
    -webkit-transform: rotate(-90deg) translateX(20px);
    transform: rotate(-90deg) translateX(20px);
  }
  .hero_btn .hero_btn_box{
    width: 50px;
    height: 140px;
    font-size: 16px;
  }
  .bar_line{
    bottom: 20px;
    height: 10px;
  }
}
@media (max-width:991px){
  .hero_btn {
    transform: translateY(10px);
  }
  .bar_text{
    -webkit-transform: rotate(-90deg) translateX(10px);
    transform: rotate(-90deg) translateX(10px);
    font-size: 14px;
  }

  .hero_btn .hero_btn_box{
    width: 30px;
    height: 100px;
  }
  .bar_line {
    bottom: 10px;
    height: 30px;
}
}
/* insustrie single page css start */
.full_content{
 max-width: 900px;
 margin: 0 auto;
}
.full_content p strong{
  color: var(--lightpink);
}

/* insustrie single page css end */

/* Service Page css Start */
.page_logo{
  padding: 30px 0;
}
.page_title{
  text-align: center;
}
.tb_space{
  margin-left: -35px;
  margin-right: -35px;
}
.tb_space > div{
  margin-top: 80px;
  padding: 0 35px;
}
.listing_Box .lb_img{
  position: relative;
  padding-bottom: 56.2%;
  display: block;
  overflow: hidden;
}
.listing_Box .lb_img img{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: all 0.5s;
}
.listing_Box .lb_img::before{
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  background-color: rgba(120, 84, 100, .5);
}
.listing_Box:hover img{
  transform: scale(1.1);
}
.listing_Box:hover .lb_img::before{
  z-index: 1;
}
.listing_Box .lb_conatent{
  padding: 30px 15px;
  text-align: center;
}
.listing_Box .lb_conatent a{
  font-weight: 700;
  color: var(--txt1);
  display: inline-block;
  
}
.listing_Box .lb_conatent h2{
  transition: all 0.5s;
}
.listing_Box .lb_conatent a:hover h2{
  color: var(--lightpink);
}
/* Service Page css End */
@media (max-width:1600px){
  .tb_space{
    margin-left: -20px;
    margin-right: -20px;
  }
  .tb_space > div{
    margin-top: 60px;
    padding: 0 20px;
  }
}
@media (max-width:1199px){
  .page_title p br{
    display: none;
  }
  .tb_space{
    margin-left: -15px;
    margin-right: -15px;
  }
  .tb_space > div{
    margin-top: 40px;
    padding: 0 15px;
  }
  .page_logo {
    padding: 20px 0;
  }
}
@media (max-width:767px){
  .page_logo {
    padding: 10px 0;
  }
  .listing_Box .lb_conatent{
    padding: 10px;
  }
}

/* blog page css start */
.blog_head{
  display: flex;
  justify-content: center;
  padding-top: 12px;
}
ul.blog_category{
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  column-gap: 40px;
  row-gap: 10px;
}


ul.blog_category li a{
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  color: var(--txt1);
}
ul.blog_category li a:hover{
  color: var(--lightpink);
}
@media (max-width:767px){
  .blog_head{
    flex-wrap: wrap;
  }
  ul.blog_category{
    column-gap: 10px;
  }
  ul.blog_category li a{
    font-size: 14px;
    background-color: rgba(73,21,197,0.1);
    display: block;
    padding: 5px 10px;
    color:var(--txt1);
    border-radius: 4px;
  }
  ul.blog_category li a:hover{
    background-color: var(--blue);
    color: var(--white);
  }
}
.load_more{
  text-align: center;
}
/* blog page css end */

/* Search toggle css start */
.search-toggle {
  display: inline-block;
  margin-left: 20px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.search-toggle .search-icon {
  font-size: 20px;
  border: none;
  padding: 0;
  margin: 0;
  width: 50px;
  height: 50px;
  border-radius: 100%;
  background-color: transparent;
  -webkit-transition: all 0.25s ease-in-out;
  -moz-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}

.search-toggle.opened .search-icon.icon-close,
.search-toggle .search-icon:is(:hover,:focus) {
  color: var(--white);
  background-color: var(--darkpink);
  display: block;
} 
/* .search-toggle .search-icon.icon-close,
.search-toggle.opened .search-icon.icon-search {
  display: none;
} */
.search-toggle .search-container {
  max-width: 30px;
  width: 100%;
  position: absolute;
  right: 260px;
  z-index: -1;
  display: flex;
  align-items: center;
  opacity: 0;
  -webkit-transition: all 0.25s ease-in-out;
  -moz-transition: all 0.25s ease-in-out;
  -ms-transition: all 0.25s ease-in-out;
  -o-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
.search-toggle .search-container.opened {
  max-width: 500px;
  opacity: 1;
  z-index: 11;
}
.search-toggle .search-container form {
  position: relative;
  max-width: 100%;
  width: 100%;
}
.search-toggle .search-container input[type="search"] {
  border: 1px solid var(--lightpink);
  border-radius: 60px;
  font-size: 16px;
  background-color: var(--white);
  padding: 14px 24px 14px 24px;
  box-shadow: 0px 5px 10px rgba(15, 15, 98, 0.15);
}
.search-toggle .search-container input[type="search"]:focus {
  outline: 0;
}

@media (max-width:767px){

  .search-toggle{
    width: 100%;
    margin-left: 0;
    margin-top: 20px;
  }
  .search-toggle .search-icon{
    display: none;
  }
  .search-toggle .search-container{
    opacity: 1;
    position: relative;
    right: unset;
    max-width: 100%;
    z-index: 1;
  }
  .search-toggle .search-container.opened{
    right: unset;
    max-width: 100%;
  }
  .search-toggle .search-container input[type="search"]{
    width: 100%;
    padding: 10px 24px;
  }
  
}

/* Search toggle css end*/
.blog_banner{
  /* background-image: url('/images/blog_banner.jpg'); */
  background-repeat: no-repeat;
  background-size: cover;
  padding: 185px 0;
  background-position: center;
  position: relative;
  background-attachment: fixed;
  
}
.blog_banner::before{
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);
}

.bb_title{
  text-align: center;
  position: relative;
  max-width: 1120px;
  margin: 0 auto;
}
.bb_title h1{
  font-size: 64px;
  font-weight: 700;
  line-height:  110%;
  text-transform: uppercase;
  margin-bottom: 0;
}
.blog_info span{
  color: var(--txt2);
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height:  100%;
  display: block;
}
.blogs_categoey{
  display: flex;
  margin-top: 20px;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
}
.blogs_categoey span{
  color: var(--txt1);
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
}
.blogs_categoey ul{
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}
.blogs_categoey ul li a{
  background-color: var(--lightpink);
  color: var(--white);
  border-radius: 5px;
  font-size: 14px;
  padding: 2px 5px;
  display: block;
  font-weight: 700;
  text-transform: capitalize;
}
.blog_side_box{
  margin-bottom: 20px;
  max-width: 374px;
  margin-left: auto;
}
.blog_side_box > img{
  /* width: 100%; */
  height: auto;
}
.need_box{
  border-radius: 10px;
  background: #000023;
  width: 100%;
  padding: 90px 10px;
  position: relative;
  overflow: hidden;
}
.need_box::before{
  content: "";
  position: absolute;
  left: -200px;
  top: -200px;
  width: 450px;
  height: 450px;
  border-radius: 225px;
  background: linear-gradient(180deg, #F8D210 0%, var(--lightpink) 100%);
  filter: blur(20px);

}
.need_box::after{
  content: "";
  position: absolute;
  right: -140px;
  bottom: -100px;
  width: 300px;
  height: 300px;
  border-radius: 150px;
  background: linear-gradient(180deg, var(--lightpink) 0%, #2FF3E0 100%);
  filter: blur(20px);
}
.nb_content{
  position: relative;
  text-align: center;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 60px;
 
}
.nb_content h2{
  color: var(--white);
  margin-bottom: 0;
}
.nb_content p{
  color: var(--white);
  margin: 0;
  max-width: 230px;

}
.nb_content a{
  display: inline-block;
  background-color: var(--lightpink);
  max-width: 212px;
  height: 40px;
  line-height: 40px;
  padding: 0 30px;
  color: var(--white);
  font-size: 16px;
  border-radius: 40px;
}
.nb_content a:hover{
  background-color: var(--blue);
}
@media (min-width:1200px){
  .container_small .container{
    max-width: 1270px;
    padding: 0 15px;
    margin: 0 auto;
  }
}
@media (max-width:1600px) {
  .bb_title{
    max-width: 800px;
  }
  .bb_title h1{
    font-size: 34px;
  }
  .blog_banner{
    padding: 150px 0;
  }
}
@media (max-width:1199px) {
  .bb_title h1{
    font-size: 32px;
  }
  .blog_banner{
    padding: 130px 0;
  }
}
@media (max-width:991px) {
  .blog_side_box{
    margin: 0 auto 20px auto;
  }
  .bb_title h1{
    font-size: 30px;
  }
  .blog_sidebar{
    margin-top: 60px;
  }
}
@media (max-width:991px) {
  .bb_title h1{
    font-size: 20px;
  }
}

/* single blog page css end */

/* work page css start */
.work_content_wrap{
  height: 100%;
  display: flex;
  align-items: center;
  padding: 50px 0;
}
.work_img{
  height: calc(100vh - 70px);
  position: relative;
}
.work_img > img{
  position: relative;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.work_screen{
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  height: 100%;
  width: calc(100% + 150px);
}
.work_screen img{
  width: 100%;
  height: 100%; 
  object-fit: contain;
  border-radius: 30px;
}
.workList section:nth-child(odd) .row{
  flex-direction: row-reverse;
}
.workList section:nth-child(odd) .work_screen{
  left: -150px;
  padding-right: 70px;
}
.workList section:nth-child(odd) .work_screen img{
  object-position: left center;
}
.workList section:nth-child(odd) .work_content{ 
  padding-left: 70px;
  padding-right: 200px;
}
.workList section:nth-child(even) .work_screen{ 
  right: -150px; 
  padding-left: 70px;
}
.workList section:nth-child(even) .work_screen img{
  object-position: right center;
}
.workList section:nth-child(even) .work_content{
  padding-right: 70px;
  padding-left: 200px;
}
@media (max-width:1600px){
  .work_screen{
    position: absolute;
    width: calc(100% + 100px);
  }
  .workList section:nth-child(odd) .work_screen{
    left: -100px;
  }
  .workList section:nth-child(odd) .work_content{
    padding-right: 130px;
  }
  .workList section:nth-child(even) .work_screen{
    right: -100px;
  }
  .workList section:nth-child(even) .work_content{
    padding-left: 130px;
  }
}
@media (max-width:991px){
  .work_img{
    height: auto;
  }
  .work_content_wrap{
    height: auto;
  }
  .work_screen{
    top: unset;
    bottom: -50px;
    transform: unset;
    height: 100%;
    width: 100%;
  }
  .workList section:nth-child(even) .work_screen img {
    object-position: center bottom;
}
  .workList section:nth-child(odd) .work_screen img {
    object-position: center bottom;
}
  .work_content_wrap{
    padding-bottom: 0;
  }
  .workList section:nth-child(odd) .work_screen{
    padding: 0 15px;
    left: 0;
  }
  .workList section:nth-child(odd) .work_content {
    padding-right: 15px;
    padding-left: 15px;
    padding-top: 60px;
  }
  .workList section:nth-child(even) .work_screen{
    padding: 0 15px;
    right: 0;
  }
  .workList section:nth-child(even) .work_content {
    padding-right: 15px;
    padding-left: 15px;
    padding-top: 60px;
  }
 
}
@media (max-width:767px){
  .work_screen img{
    border-radius: 10px;
  }
}
/* work page css end */


/*Default Typography*/
.default_typo blockquote {
  margin: 1em 0;
  padding: 25px;
  font-style: normal;
  border-left: 10px solid var(--blue);
  background-color: rgb(247 247 247);
  }
.default_typo blockquote p{margin-top: 0;}
.default_typo blockquote p:last-child{margin-bottom: 0;}
.default_typo p:last-child{
  margin-bottom: 0;
}
.default_typo blockquote span{
  display: block;
  font-weight: 700;
  }
.default_typo h1{
  padding-top: 20px;
  font-weight: 700;
  margin-bottom: 15px;
  font-size: 34px;
  }
.default_typo h2{
  font-size: 34px;
  font-weight: 700;
  padding-top: 20px;
  margin-bottom: 15px;
  }
.default_typo h3{
  font-size: 28px;
  font-weight: 700;
  padding-top: 20px;
  margin-bottom: 15px; 
  }
.default_typo h4{
  font-size: 24px;
  padding-top: 20px; 
  font-weight: 700;
  margin-bottom: 15px;
}
.default_typo h5{
  font-size: 20px;
  padding-top: 20px; 
  margin-bottom: 15px;
  font-weight: 700;
}
.default_typo h6{
  font-size: 16px;
  padding-top: 20px; 
  margin-bottom: 15px;
  font-weight: 700;
}
.default_typo h1:first-child,
.default_typo h2:first-child,
.default_typo h3:first-child,
.default_typo h4:first-child,
.default_typo h5:first-child,
.default_typo h6:first-child
{padding-top: 0;}
.default_typo img{
  height: auto;
}
.default_typo p + figure,
.default_typo p + img{
  margin-top: 20px;
}
/* .default_typo img + h1,
.default_typo img + h2,
.default_typo img + h3,
.default_typo img + h4,
.default_typo img + h5,
.default_typo img + h6 
{padding-top: 0;} */
.default_typo ul{
  margin-bottom: 20px;
  padding-left: 0px;
  }
.default_typo ul li ul.wsp-posts-list,
.default_typo ul li ul.children{margin-top: 12px;}
.default_typo ul li{
  position: relative; 
  list-style: none;
  padding-left: 26px;
  padding-bottom: 6px;
  }
.default_typo ul.divide{
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap; 
}
.default_typo ul.divide li{
  position: relative;
  width: 100%;
}
.default_typo ul.divide.two li{
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%; 
}
.default_typo ul.divide.three li{
  -ms-flex: 0 0 33.333%;
  flex: 0 0 33.333%;
  max-width: 33.333%; 
}
.default_typo ul.divide.four li{
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%; 
}
.default_typo p a{
  color: var(--blue);
  font-weight: 600;
}
.default_typo p a:hover{
  color: var(--lightpink);
}
.default_typo ul li a{
  text-decoration: none;
  color: var(--blue);
  }
.default_typo ul li a:hover,
.default_typo ul li a:focus{color: var(--lightpink);}
.default_typo ul li i{
  font-size: 21px;
  display: inline-block;
  vertical-align: middle;
  }
.default_typo ul li:before{
  position: absolute;
  left: 0;
  top: 0;
  content:"\f138";
  font-family:'FontAwesome';
  /* color: #ff5600; */
  }
.default_typo ol {
  margin-left: 20px;
  padding: 0;
  }
.default_typo ol li{
  list-style-type: decimal;
  padding-left: 10px;
  padding-bottom: 8px; 
  }
.default_typo ul li ul{
  margin-bottom: 0px;
  margin-top: 15px;
  }
.default_typo ul li ul li:before{content:"\f0da";}
.default_typo ul li ul li{padding-left: 21px;}
.default_typo ul li ol{
  margin-bottom: 0px;
  margin-top: 15px;
  }
.default_typo ul li ol li:before{display: none;}
.default_typo ol li ol{
  margin-bottom: 0px;
  margin-top: 15px;
  }
.default_typo ol li ul{
  margin-bottom: 0px;
  margin-top: 15px;
  }
.default_typo ol li ul li{
  position: relative;
  list-style: none;
  padding-left: 21px;
  padding-bottom: 10px;
  }
.default_typo ol li ul li:before{content:"\f0da";}

/*default wordpress editor images*/
.alignleft,
.aligncenter,
.alignright 
{border-radius: 4px;}
.alignleft {
  max-width: 470px;
  object-fit: cover;
  margin: 0px 30px 15px 0px;
  float: left;
  overflow: hidden; 
  }
.aligncenter {
  display: block;
  margin: 0 auto;
  margin-bottom: 30px;
  max-width: 100%;
  overflow: hidden;
  }
.alignright {
  max-width: 470px;
  object-fit: cover;
  margin: 0px 0 15px 30px;
  float: right;
  overflow: hidden;
  }


@media(max-width:1199px) {
  .default_typo h1{font-size: 32px;}
  .default_typo h2{font-size: 30px;}
  .default_typo h3{font-size: 26px;}
  .default_typo h4{font-size: 22px;}
 
  
  .default_typo ul.divide.four li{
      -ms-flex: 0 0 33.333%;
      flex: 0 0 33.333%;
      max-width: 33.333%; 
  }
}
@media(max-width:991px) {
  .alignright,.alignleft,.aligncenter {
      margin: 0 0 30px 0;
      float: none;
      max-width: 100%;
  }
  .default_typo blockquote {
      margin: 0.8em 0;
      padding: 20px;
      border-left: 8px solid var(--blue);
  }
  .default_typo h1{
      font-size: 30px;
      padding-top: 15px; 
      margin-bottom: 12px;
      }
  .default_typo h2{
      font-size: 28px;
      padding-top: 15px;
      margin-bottom: 12px;
      }
  .default_typo h3{
      font-size: 24px;
      padding-top: 15px;
      margin-bottom: 12px;
      }
  .default_typo h4{ 
      font-size: 20px;
      padding-top: 15px;
      margin-bottom: 12px;
      }
  .default_typo ul.divide.three li{
      -ms-flex: 0 0 50%;
      flex: 0 0 50%;
      max-width: 50%;
  }
  .default_typo ul.divide.four li{
      -ms-flex: 0 0 50%;
      flex: 0 0 50%;
      max-width: 50%;
  }
}
@media(max-width:767px) {
  .alignright,.alignleft,.aligncenter {margin: 0 0 25px 0;}
  .default_typo blockquote {
      margin: 0.7em 0;
      padding: 15px;
      border-left: 6px solid var(--blue);
  }
  .default_typo h1{font-size: 20px;}
  .default_typo h2{font-size: 20px;}
  .default_typo h3{font-size: 18px;}
  .default_typo h4{font-size: 16px;}

  .default_typo ul.divide.two li{
      -ms-flex: 0 0 100%;
      flex: 0 0 100%;
      max-width: 100%; 
  }
  .default_typo ul.divide.three li{
      -ms-flex: 0 0 100%;
      flex: 0 0 100%;
      max-width: 100%;
  }
  .default_typo ul.divide.four li{
      -ms-flex: 0 0 100%;
      flex: 0 0 100%;
      max-width: 100%;
  }
}
@media(max-width:575px) {
  .alignright,.alignleft,.aligncenter {margin: 0 0 20px 0;}

}
/*End Default Typography*/

/* faqs Page css start */
.faqs_banner{
  padding: 70px 0;
}
.faqs_banner .left_banner .caption,
.faqs_banner .left_banner{
  height: 100%;
}
.faqs_banner .left_banner .caption{
 display: flex;
 flex-direction: column;
}
.faqs_banner .left_banner .caption .slider_main{
  margin-top: auto;
}
@media (max-width:1199px){
  .faqs_banner .left_banner .caption, 
  .faqs_banner .left_banner{
    height: auto;
  }
  .faqs_banner .left_banner .caption .slider_main{
    margin-top: 40px;
  }
  .faqs_banner .left_banner{
    padding-bottom: 60px;
  }
  .faqs_banner{
    padding-top: 0;
    padding-bottom: 60px;
  }
}
/* faqs Page css end */

.skeleton-image {
  width: 100%;
  height: 280px; /* Adjust height according to your image height */
  background-color: #f0f0f0; /* Light gray background */
  border-radius: 8px;
  margin-bottom: 10px;
}

.skeleton-title {
  width: 100%;
  height: 24px;
  background-color: #f0f0f0; /* Light gray background */
  border-radius: 4px;
}

/* Add animation to simulate loading effect */
@keyframes loading {
  0% {
    opacity: 0.5;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.5;
  }
}

/* Apply loading animation to skeleton elements */
.skeleton-image,
.skeleton-title {
  animation: loading 1.5s infinite;
}
.scroll_down{
  background: var(--white);
  padding: 10px;
  position: absolute;
  top: -40px;
  left: 50%;
  transform: translateX(-50%);
}
.scroll_down button{
  display: block;
  padding: 0;
  border: 0;
  background-color: transparent;
  outline: none;
}
.scroll_down button img{
  height: auto;
}
@media (max-width:1600px){
  .scroll_down{
    top: -34px;
  }
  .scroll_down button img{
    width: 80px;
  }
}
@media (max-width:1199px){
  .scroll_down{
    top: -23px;
  }
  .scroll_down button img{
    width: 60px;
  }
}


.form_box form{
  background-color: var(--black);
  padding: 30px;
  border-radius: 10px;
}
.form_box form h2{
  color: var(--lightpink);
  text-transform: capitalize;
}

.blog_form .input_wraper{
  margin-bottom: 15px;
}
.button_wraper1{
  display: flex;
  align-items: center;
  gap: 15px;
}
.blog_form textarea{
  height: 120px;
  border-radius: 30px;
  padding: 17px;
}
.blog_form input{
  border-radius: 30px;
  font-size: 16px;
}
.blog_form input[type="submit"]{
  border-radius: 30px;
  padding: 0 40px;
  font-size: 16px;
}
@media (max-width:767px) {
  .form_box form{
    padding: 15px;
  }
}
#getintouch{
  scroll-margin-top: 70px;
}
.alert{
  font-size: 14px;
  padding: 10px;
}
/* why us page css start */
.why_us_list section{
 padding-top: 50px;
 padding-bottom: 50px;
}
.why_us_list section:first-child{
  padding-top: 0;
}
.why_us_list section:nth-child(odd) .row{
  flex-direction: row-reverse;
}

.why_us_list .why_us_Content{
  padding: 0 70px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.why_us_list .why_us_Content .number_wrap{
  position: relative;
}
.why_us_list .why_us_Content .number_wrap span{
  color: #F3F4F4;
  font-family: var(--montserrat);
  font-weight: 700;
  font-size: 170px;
  line-height: 0.8;
}
.why_us_list .why_us_Content .number_wrap h2{
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  margin: 0;
}
.why_us_img{
  width: 100%;
  height: 100vh;
  overflow: hidden;
  position: relative;
  padding-bottom: 70px;
 
}
.why_us_img img{
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: relative;

}
.why_us_list section .why_us_img::before{
  content: "";
  position: absolute;
  bottom: 0;
  width: calc(100% - 70px);
  height:calc(100% - 70px);
}
.why_us_list section:nth-child(odd) .why_us_img::before{
  right: 0;
  background: linear-gradient(307deg,#f70b7f 3%,#9a249c 59%,#4f0dcf 97%);
}
.why_us_list section:nth-child(odd) .why_us_img{
  padding-right: 70px;
}
.why_us_list section:nth-child(even) .why_us_img::before{
  left: 0;
  background: #1D252C;
}
.why_us_list section:nth-child(even) .why_us_img{
  padding-left: 70px;
}
@media (max-width:1600px){
  .why_us_list .why_us_Content .number_wrap span{
    font-size: 150px;
  }
}
@media (max-width:1199px){
  .why_us_list .why_us_Content .number_wrap span{
    font-size: 130px;
  }
}
@media (max-width:991px){
  .why_us_list .why_us_Content .number_wrap span{
    font-size: 110px;
  }
  .why_us_img{
    height: auto;
  }
  .why_us_img img{
    max-height: 600px;
  }
  .why_us_list section{
    padding-top: 40px;
    padding-bottom: 40px;
   }
   .why_us_list .why_us_Content{
      padding: 40px 70px 0 70px;
   }

}
@media (max-width:767px){
  .why_us_img{
    padding-bottom: 15px;
  }
  .why_us_list .why_us_Content {
    padding: 40px 15px 0 15px;
  }
  .why_us_list section .why_us_img::before{
    width: calc(100% - 15px);
    height:calc(100% - 15px);
  }
  .why_us_list section:nth-child(odd) .why_us_img{
    padding-right: 15px;
  }
  .why_us_list section:nth-child(even) .why_us_img {
    padding-left: 15px;
}
  .why_us_list .why_us_Content .number_wrap span{
    font-size: 90px;
  }
  .why_us_list section{
    padding-top: 30px;
    padding-bottom: 30px;
   }
  
}

/* why us page css end */

/* single work page start */
.project_screen{
  text-align: center;
}
.feature_project .container-fluid{
  padding: 0;
}
.feature_project_img{
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 100vh;
}
.feature_project_img img{
  object-fit: cover;
  height: 100%;

}
.new_feature_project_content{
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  background: #F3F4F4;
  padding: 40px 70px;
}
@media (max-width:991px){
  .feature_project_img{
    min-height: auto;
    height: auto;
  }
  .new_feature_project_content{
    height: auto;
    padding: 40px 15px;
  }
 
}
/* single work page end */

/* sitemap page start */
.sm_box{
  margin-top: 20px;
}
.sm_box h3{
  border-bottom: 1px solid rgba(29, 37, 44, 0.15);
  padding-bottom: 20px;
  font-weight: bold;
}
.sm_box ul{
  padding-left: 20px;
}
.sm_box ul li{
  margin: 8px 0;
  list-style-type: circle;
}
.sm_box ul li a{
  position: relative;
  display: block;
  color: var(--black);
  font-weight: 500;
  font-size: 16px;
}
.sm_box ul li a:hover{
  color: var(--lightpink);
}
@media (max-width:1400px){
  .sm_box ul li a{
    font-size: 15px;
  }
}
@media (max-width:767px){
  .sm_box h3{
    padding-bottom: 10px;
    margin-bottom: 10px;
  }
}
/* sitemap page end */

/* Slick Slider */
.slick-prev, .slick-next{
  z-index: 1;
}
/* Slick Slider */

.pnf_copy{padding-top: 80px;}
@media (max-width:1199px){
  .pnf_copy{padding-top: 0px;}
}


/* Industries contact form start */

.sc_form .input_wraper{
  margin-bottom: 20px;
}
.sc_form label{
  font-size: 16px;
}

.submit_wraper{
  display: flex;
  /* justify-content: flex-end; */
  margin-top: 30px;
}
.sc_form select:focus, 
.sc_form  textarea:focus, 
.sc_form input[type="tel"]:focus, 
.sc_form input[type="number"]:focus, 
.sc_form input[type="date"]:focus, 
.sc_form input[type="email"]:focus, 
.sc_form input[type="text"]:focus{
  border: 1px solid var(--lightpink);
}
.sc_form p.error{
  color: red;
}
.ceo_wrap{
  margin-bottom: 5px;
}
.sc_form .submit_wraper{
  align-items: center;
}
.ceo_wrap .sc_img{
  width: 70px;
  height: 70px;
  border-radius: 50%;
  overflow: hidden;
}
.ceo_wrap .sc_img img{
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top;
}
.ceo_wrap span{
  font-size: 14px;
  display: block;
  margin-top: 5px;
}
@media (max-width:991px){
  .sc_form_wraper{
    margin-top: 30px;
  }
}
@media (max-width:767px){
  .sc_form .input_wraper{
      margin-bottom: 15px;
  }
  .sc_form select, 
  .sc_form textarea, 
  .sc_form input[type="tel"], 
  .sc_form input[type="number"], 
  .sc_form input[type="date"], 
  .sc_form input[type="email"], 
  .sc_form input[type="text"]{
    height: 50px;
  }
}

.checkbox_wraper{

}
input[type="checkbox"]{
  width: 18px;
  height: 18px;
  accent-color: var(--lightpink);
}
.checkbox_wraper{
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 15px;
}
.checkbox_wraper label{
  display: flex;
  align-items: center;
  column-gap: 5px;
  font-weight: 600;
  margin-top: 10px;
}
.next_section + section{
  padding-top: 0;
}

/* Industries contact form end */

.enqury_content_box a{color: var(--darkpink);font-weight: 500;}